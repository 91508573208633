import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LangSelector.css';
import axios from 'axios';

function LanguageSelector() {
  const { i18n } = useTranslation();
  
  useEffect(() => {
    detectLocationAndSetLanguage(i18n, (language) => {
      i18n.changeLanguage(language); // Set the language
    });
  }, [i18n]);

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  // Function to detect location and set language
  const detectLocationAndSetLanguage = async (i18n, setLanguageCallback) => {
    try {
      // Fetch the user's   IP information
      const response = await axios.get('https://ipinfo.io');
      const data = response.data;
  
      // Detect language based on IP location
      if (data.country === 'HU') {
        setLanguageCallback('hu'); // Hungarian
      } else if (['DE', 'AT', 'CH'].includes(data.country)) {
        setLanguageCallback('de'); // German
      } else {
        setLanguageCallback('en'); // Default to English
      }
    } catch (error) {
      console.error('Error detecting location:', error);
    }
  };

  return (
    <div className="language-selector">
      <select onChange={changeLanguage} value={i18n.language}>
        <option value="en">EN</option>
        <option value="de">DE</option>
        <option value="hu">HU</option>
      </select>
    </div>
  );
}

export default LanguageSelector;
