import './MainFront.css';
import SaveContactButton from './SaveContactButton'
import SocialLinks from './SocialLinks'
import Titles from './Titles';
import LangSelector from './LangSelector';
import { useTranslation } from 'react-i18next';

function MainFront() {
  const { t } = useTranslation();
  
  return (
    <div className="main-front">
      <LangSelector/>
      <h1>{t('name')}</h1>
      <Titles/>
      <SaveContactButton
        name="Szabo Kristof"
        phoneNumber="+36703107317"
        email="business@szabokristof.eu"
        picture="https://example.com/szabo_kristof.jpg"
        website="szabokristof.eu"
        title="Software Engineer"
      />
      <SocialLinks/>
    </div>
  );
}

export default MainFront;
