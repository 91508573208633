import React, { useState } from 'react';
import './SaveContactButton.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';

const SaveContactButton = ({ name, phoneNumber, email, picture, website, title, description }) => {
  const { t } = useTranslation();
  const [downloadState, setDownloadState] = useState(false)

  const handleSaveContact = () => {
    // Create a vCard string
    const vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${name}
TEL:${phoneNumber}
EMAIL:${email}
PHOTO;VALUE=URL:${picture}
URL:${website}
TITLE:${title}
END:VCARD`;

    // Create a Blob containing the vCard data
    const blob = new Blob([vCardData], { type: 'text/vcard' });

    // Create a URL for the Blob
    const blobURL = URL.createObjectURL(blob);

    // Create a link element and trigger a download
    const a = document.createElement('a');
    a.href = blobURL;
    a.download = `${name}.vcf`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    // Clean up
    URL.revokeObjectURL(blobURL);
    document.body.removeChild(a);

    setDownloadState(true)
  };

  return (
    <div className='SaveContact'>
      <button
        className='SaveContactButton'
        onClick={handleSaveContact}>
        {t('Save Contact')}
      </button>
      {downloadState && <p>{t('Save msg')}</p>}
    </div>
    
  );
};

export default SaveContactButton;
