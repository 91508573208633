import React from 'react';
import './Titles.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';

const Titles = () => {
    const { t } = useTranslation()
    const titles = t('Titles')

    return (
        <div className="titles">
            <div className='title-box'>
                <p>{titles.Software_Engineer} | </p>
                <a href='https://www.logiscool.com' target="_blank" rel="noopener noreferrer">
                    <img src="/images/linkedSites/LSC.png" alt='Logiscool ltd.'></img>
                </a>
            </div>
            <div className='title-box'>
                <p>{titles.Computer_Science_Student} | </p>
                <a href='https://www.elte.hu/en/faculties/informatics' target="_blank" rel="noopener noreferrer">
                    <img src="/images/linkedSites/ELTE.png" alt='ELTE'></img>
                </a>
            </div>
            <div className='title-box'>
                <p>{titles.Private_Programming_Tutor}</p>
            </div>
            <div className='title-box'>
                <p>{titles.Vice_President} | </p>
                <a href='https://www.aldeparty.eu/momentum_mozgalom' target="_blank" rel="noopener noreferrer">
                    <img src="/images/linkedSites/Momentum.png" alt='Momentum Törökbálint'></img>
                </a>
            </div>
            <p></p>
        </div>
    );
}; 

export default Titles;
