// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json'; // English translations
import translationDE from './locales/de.json'; // German translations
import translationHU from './locales/hu.json'; // Hungarian translations

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
      hu: {
        translation: translationHU,
      },
      // Add resources for other languages here
    },
    lng: 'en', // Set the default language
    fallbackLng: 'en', // Fallback language if a translation is missing
    debug: true, // Enable debug mode
    returnObjects: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
