import './App.css';
import MainFront from './Componennts/MainFront'
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <MainFront/>
    </div>
  );
}

export default App;
